var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-lan" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              _vm.isEdit && !_vm.spinners.loading
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(_vm._s(this.itemName)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: { name: "Hubs" } } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          !_vm.isValid
            ? _c("form-summary", {
                staticClass: "form-errors alert alert-danger",
                attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
              })
            : _vm._e(),
          !_vm.spinners.loading
            ? _c(
                "CForm",
                { staticClass: "hub-form" },
                [
                  _c(
                    "CTabs",
                    {
                      staticClass: "tab-menu",
                      attrs: {
                        "active-tab": _vm.activeTab,
                        variant: "pills",
                        vertical: {
                          navs: "col-xl-2 col-md-3",
                          content: "col-xl-10 col-md-9",
                        },
                      },
                      on: {
                        "update:activeTab": function ($event) {
                          _vm.activeTab = $event
                        },
                        "update:active-tab": function ($event) {
                          _vm.activeTab = $event
                        },
                      },
                    },
                    [
                      _c(
                        "CTab",
                        { attrs: { active: "" } },
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _c("CIcon", { attrs: { name: "cil-task" } }),
                              _vm._v(" General Information "),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-3 d-flex flex-column flex-md-row",
                              },
                              [
                                _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    label: "Name:",
                                    placeholder: "Name",
                                    lazy: false,
                                    value: _vm.$v.form.name.$model,
                                    isValid: _vm.checkIfValid("name"),
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.name,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "section",
                              { staticClass: "address" },
                              [
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    id: "verify_address",
                                    value: _vm.verified_address,
                                    label: "Verify Address From Google:",
                                    placeholder: "Enter a location",
                                    lazy: false,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      _vm.verified_address = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "CButton",
                                              { attrs: { color: "info" } },
                                              [
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-location-pin",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2480037663
                                  ),
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column flex-md-row",
                                  },
                                  [
                                    _c("CTextarea", {
                                      staticClass: "w-100",
                                      attrs: {
                                        rows: "2",
                                        label: "Address:",
                                        placeholder:
                                          "Street name and house number",
                                        lazy: false,
                                        value: _vm.$v.form.address.$model,
                                        isValid: _vm.checkIfValid("address"),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.address,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column flex-md-row",
                                  },
                                  [
                                    _c("CSelect", {
                                      staticClass: "w-100 mr-2",
                                      attrs: {
                                        label: "Country:",
                                        placeholder: "Please select..",
                                        lazy: false,
                                        options: _vm.authCountries,
                                        value: _vm.$v.form.country_id.$model,
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.country_id,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c("CInput", {
                                      staticClass: "w-100",
                                      attrs: {
                                        label: "City:",
                                        placeholder: "City",
                                        lazy: false,
                                        value: _vm.$v.form.city.$model,
                                        isValid: _vm.checkIfValid("city"),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.city,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column flex-md-row",
                                  },
                                  [
                                    _c("CInput", {
                                      staticClass: "w-100 mr-2",
                                      attrs: {
                                        type: "number",
                                        label: "Postal Code:",
                                        placeholder: "Zip or Postal Code",
                                        lazy: false,
                                        value: _vm.$v.form.zip.$model,
                                        isValid: _vm.checkIfValid("zip"),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.zip,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c("CInput", {
                                      staticClass: "w-100 mr-2",
                                      attrs: {
                                        readonly: "",
                                        label: "Latitude:",
                                        placeholder: "Latitude",
                                        lazy: false,
                                        value: _vm.$v.form.lat.$model,
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.lat,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "CLoadingButton",
                                                  {
                                                    attrs: {
                                                      loading:
                                                        _vm.spinners.loading,
                                                      color:
                                                        _vm.isCoordinateUptodate &&
                                                        !!_vm.form.lat &&
                                                        !!_vm.form.lat
                                                          ? "dark"
                                                          : "warning",
                                                      disabled:
                                                        _vm.isCoordinateUptodate &&
                                                        !!_vm.form.lat &&
                                                        !!_vm.form.lat,
                                                      onChange:
                                                        _vm.getCoordinateFromAddress,
                                                    },
                                                  },
                                                  [
                                                    _c("CIcon", {
                                                      attrs: {
                                                        name: "cil-sync",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1941610544
                                      ),
                                    }),
                                    _c("CInput", {
                                      staticClass: "w-100",
                                      attrs: {
                                        readonly: "",
                                        label: "Longitude:",
                                        placeholder: "Longitude",
                                        lazy: false,
                                        value: _vm.$v.form.lng.$model,
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.lng,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "CTab",
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              [
                                _c("CIcon", { attrs: { name: "cil-group" } }),
                                _vm._v(" Couriers"),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "CLink",
                            {
                              staticClass: "mb-3 d-block",
                              on: {
                                click: function ($event) {
                                  _vm.addCouriersModalShow = true
                                },
                              },
                            },
                            [
                              _c("CIcon", { attrs: { name: "cil-plus" } }),
                              _vm._v(" Add couriers to the hub "),
                            ],
                            1
                          ),
                          _c("CourierList", {
                            attrs: { couriers: _vm.form.couriers },
                            on: {
                              "update:couriers": function ($event) {
                                return _vm.$set(_vm.form, "couriers", $event)
                              },
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "CTab",
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-location-pin" },
                                }),
                                _vm._v(" Scopes"),
                              ],
                              1
                            ),
                          ]),
                          _c("scopes", {
                            attrs: {
                              active: _vm.activeTab == 2,
                              scopes: _vm.form.scopes,
                              zip: _vm.form.zip,
                              lat: _vm.form.lat,
                              lng: _vm.form.lng,
                              country_id: _vm.form.country_id,
                            },
                            on: {
                              "update:scopes": function ($event) {
                                return _vm.$set(_vm.form, "scopes", $event)
                              },
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("CSpinner", { attrs: { color: "primary" } }),
        ],
        1
      ),
      _c("CCardFooter", { staticClass: "sticky-bottom" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-wrap align-items-center",
            staticStyle: { gap: "0.75rem" },
          },
          [
            _c(
              "CButton",
              {
                attrs: {
                  color: "primary",
                  disabled: _vm.spinners.loading || _vm.spinners.btnSubmit,
                },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm.spinners.btnSubmit
                  ? _c("CSpinner", { attrs: { size: "sm" } })
                  : _vm._e(),
                _vm._v(" Submit "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "CModal",
        {
          staticClass: "add-couriers-modal",
          attrs: {
            title: `Add Couriers to ${_vm.form.name || "the hub"}`,
            color: "white",
            size: "lg",
            show: _vm.addCouriersModalShow,
            closeOnBackdrop: false,
          },
          on: {
            "update:show": function ($event) {
              _vm.addCouriersModalShow = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.addCouriersModalShow = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        color: "success",
                        disabled: !_vm.newCouriers.length,
                      },
                      on: { click: _vm.addCouriers },
                    },
                    [
                      _c("CIcon", {
                        staticClass: "mt-0",
                        attrs: { name: "cil-plus" },
                      }),
                      _vm._v(" Add "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("add-couriers", {
            attrs: {
              active: _vm.addCouriersModalShow,
              "courier-ids": _vm.transformedForm?.couriers,
              "selected-items": _vm.newCouriers,
              options: { excludeSubordinates: false },
            },
            on: {
              "update:selectedItems": function ($event) {
                _vm.newCouriers = $event
              },
              "update:selected-items": function ($event) {
                _vm.newCouriers = $event
              },
            },
          }),
        ],
        1
      ),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.spinners.btnSubmit,
            expression: "spinners.btnSubmit",
          },
        ],
        attrs: { opacity: 0.8 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }