<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-lan"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit && !spinners.loading">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>

      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'Hubs' }">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <form-summary
        v-if="!isValid"
        class="form-errors alert alert-danger"
        :validator="$v.form"
        :attributes="attributes"
      />

      <CForm class="hub-form" v-if="!spinners.loading">
        <CTabs class="tab-menu" :active-tab.sync="activeTab" variant="pills" :vertical="{ navs: 'col-xl-2 col-md-3', content: 'col-xl-10 col-md-9' }">
          <CTab active>
            <template slot="title">
              <CIcon name="cil-task"/> General Information
            </template>

            <div class="d-flex flex-column">
              <!-- name -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  label="Name:"
                  placeholder="Name"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                />
              </div>

              <!-- address section -->
              <section class="address">
                <!-- Verify Address From Google -->
                <CInput
                  id="verify_address"
                  :value.sync="verified_address"
                  class="w-100"
                  label="Verify Address From Google:"
                  placeholder="Enter a location"
                  :lazy="false"
                >
                  <template #prepend>
                    <CButton color="info"><CIcon name="cil-location-pin"/></CButton>
                  </template>
                </CInput>

                <!-- address -->
                <div class="d-flex flex-column flex-md-row">
                  <CTextarea
                    class="w-100"
                    rows="2"
                    label="Address:"
                    placeholder="Street name and house number"
                    :lazy="false"
                    :value.sync="$v.form.address.$model"
                    :isValid="checkIfValid('address')"
                  />
                </div>

                <!-- country, city -->
                <div class="d-flex flex-column flex-md-row">
                  <CSelect
                    class="w-100 mr-2"
                    label="Country:"
                    placeholder="Please select.."
                    :lazy="false"
                    :options="authCountries"
                    :value.sync="$v.form.country_id.$model"
                  />

                  <CInput
                    class="w-100"
                    label="City:"
                    placeholder="City"
                    :lazy="false"
                    :value.sync="$v.form.city.$model"
                    :isValid="checkIfValid('city')"
                  />
                </div>

                <!-- zip, lat, lng -->
                <div class="d-flex flex-column flex-md-row">
                  <CInput
                    type="number"
                    class="w-100 mr-2"
                    label="Postal Code:"
                    placeholder="Zip or Postal Code"
                    :lazy="false"
                    :value.sync="$v.form.zip.$model"
                    :isValid="checkIfValid('zip')"
                  />

                  <CInput
                    readonly
                    class="w-100 mr-2"
                    label="Latitude:"
                    placeholder="Latitude"
                    :lazy="false"
                    :value.sync="$v.form.lat.$model"
                  >
                    <template #prepend>
                      <CLoadingButton
                        :loading="spinners.loading"
                        :color="(isCoordinateUptodate && !!form.lat && !!form.lat) ? 'dark' : 'warning'"
                        :disabled="isCoordinateUptodate && !!form.lat && !!form.lat"
                        :onChange="getCoordinateFromAddress"
                      >
                        <CIcon name="cil-sync"/>
                      </CLoadingButton>
                    </template>
                  </CInput>

                  <CInput
                    readonly
                    class="w-100"
                    label="Longitude:"
                    placeholder="Longitude"
                    :lazy="false"
                    :value.sync="$v.form.lng.$model"
                  />
                </div>
              </section>
            </div>
          </CTab>

          <CTab>
            <template slot="title">
              <div><CIcon name="cil-group"/> Couriers</div>
            </template>

            <CLink @click="addCouriersModalShow = true" class="mb-3 d-block">
              <CIcon name="cil-plus" /> Add couriers to the hub
            </CLink>

            <CourierList :couriers.sync="form.couriers" />
          </CTab>

          <CTab>
            <template slot="title">
              <div><CIcon name="cil-location-pin"/> Scopes</div>
            </template>

            <scopes
              :active="activeTab == 2"
              :scopes.sync="form.scopes"
              :zip="form.zip"
              :lat="form.lat"
              :lng="form.lng"
              :country_id="form.country_id"
            />
          </CTab>
        </CTabs>
      </CForm>

      <CSpinner v-else color="primary" />
    </CCardBody>

    <CCardFooter class="sticky-bottom">
      <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
        <CButton
          color="primary"
          :disabled="spinners.loading || spinners.btnSubmit"
          @click="submit()"
        >
          <CSpinner v-if="spinners.btnSubmit" size="sm" /> Submit
        </CButton>
      </div>
    </CCardFooter>

    <CModal
      class="add-couriers-modal"
      :title="`Add Couriers to ${form.name || 'the hub'}`"
      color="white"
      size="lg"
      :show.sync="addCouriersModalShow"
      :closeOnBackdrop="false"
    >
      <add-couriers
        :active="addCouriersModalShow"
        :courier-ids="transformedForm?.couriers"
        :selected-items.sync="newCouriers"
        :options="{ excludeSubordinates: false }"
      />

      <template #footer class="p-1">
        <CButton @click="addCouriersModalShow = false" color="link">Close</CButton>
        <CButton @click="addCouriers" color="success" :disabled="!newCouriers.length">
          <CIcon name="cil-plus" class="mt-0" /> Add
        </CButton>
      </template>
    </CModal>

    <mc-spinner :opacity="0.8" v-show="spinners.btnSubmit" />
  </CCard>

</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';
import Scopes from './Scopes'
import CourierList from "@/components/Courier/CourierList";
import AddCouriers from "@/components/Courier/AddCouriers";

export default {
  name: 'Hub',
  components: {
    CourierList,
    Scopes,
    'add-couriers': AddCouriers
  },

  data() {
    return {
      activeTab: 0,
      form: {},
      isEdit: false,
      spinners: {
        loading: false,
        btnSubmit: false,
      },

      itemName: '',
      unsavedChanges: false,
      newCouriers: [],
      addCouriersModalShow: false,

      // Google Maps API Autocomplete
      verified_address: '',
      autocomplete_address: null,
      isCoordinateUptodate: false,

      // Vuelidate-error-extractor
      attributes: {
        name: 'Hub name',
        address: 'Address',
        city: 'City',
        zip: 'Postal code',
        country_id: 'Country',
      },
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      name: { required, minLength: minLength(3) },
      address: { required },
      city: { required },
      zip: { required },
      lng: { },
      lat: { },
      country_id: { required },
    }
  },

  // Route koruması için
  beforeRouteLeave (to, from , next) {
    if (this.unsavedChanges === true) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      this.unsavedChanges = !answer;
      next(answer) // answer: true or false
    } else {
      next()
    }
  },

  async mounted() {
    // this.updateCoordinate = _debounce(async (zip) => {
    //   if (zip && zip.length > 3) {
    //     try {
    //       const { lng, lat } = await this.getCoordinateFromPostalCode();
    //       Object.assign(this.form, { lng, lat });
    //     } catch (error) {
    //       this.$toast.error('Location not available. Check the postal code!');
    //       return;
    //     }
    //   }
    // }, 1000);

    this.activeTab = this.$route.query.tab ? parseInt(this.$route.query.tab) : 0;

    // F5 veya başka bir siteye gitmeden önce 'Yaptığınız değişiklikler kaydedilmemiş olabilir.' koruması için
    window.onbeforeunload = () => this.unsavedChanges === true ? true : null;

    this.$watch('form', (newV, oldV) => {
      const initialState = sessionStorage.getItem(`hub-form`);
      const newState = JSON.stringify(this.transformedForm);

      this.unsavedChanges = initialState !== newState;
    }, { deep: true });

    this.form = await this.getForm(this.itemId)
    sessionStorage.setItem(`hub-form`, JSON.stringify(this.transformedForm));


    // Google Maps API Autocomplete: Place Autocomplete Address Form
    this.autocomplete_address = new google.maps.places.Autocomplete(
      document.getElementById("verify_address"),
      {
        componentRestrictions: { country: [this.country?.iso2 ?? 'BE'] },
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["address"],
      }
    );
    this.autocomplete_address.addListener("place_changed", this.setPlace);


    // Watch address fields
    this.$watch('addressFields', (newV, oldV) => {
      this.isCoordinateUptodate = false;
    });
    this.$watch('form.country_id', (newV, oldV) => {
      this.autocomplete_address.setComponentRestrictions({ country: [this.country?.iso2  ?? 'BE'] });
    });

    this.isCoordinateUptodate = true;
  },

  computed: {
    ...mapGetters(["errors"]),
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    itemId() { return Number(this.$route.params.id) || null },
    addressFields() {
      return `${this.form.address}|${this.form.zip}|${this.form.city}|${this.form.country_id}`;
    },
    country() {
      return this.authCountries.find((item) => item.value == (this.form.country_id || this.$store.state.countryId));
    },
    transformedForm() {
      return {
        ...this.form,
        couriers: this.form.couriers?.map(courier => courier.id).join(','),
        scopes: this.form.scopes?.map(scope => scope.id).join(','),
      }
    }
  },

  methods: {
    checkIfValid (fieldName) {
      const fields = fieldName.split(".");
      const field = fields.length > 1
        ? this.$v.form[fields[0]][fields[1]]
        : this.$v.form[fieldName];

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    async submit() {
      window.scrollTo(0, 0);

      if (!this.isValid) {
        this.validate();
        this.activeTab = 0;
        return;
      }

      // If the latitude and longitude is not up to date or null
      if (!this.isCoordinateUptodate || !this.form.lat || !this.form.lng) {
        await this.getCoordinateFromAddress();
      }

      // try {
      //   const { lng, lat } = await this.getCoordinateFromPostalCode();
      //   Object.assign(this.form, { lng, lat });
      // } catch (error) {
      //   this.$toast.error('Location not available. Check the postal code!');
      //   return;
      // }

      if (this.isEdit) {
        await this.update()
      } else {
        await this.store()
      }
    },

    async update() {
      this.spinners.btnSubmit = true

      await this.$axios.put(this.$backend.COURIER.HUBS.UPDATE.replace("{id}", this.itemId), this.transformedForm)
        .then(({ data }) => {
          this.itemName = data.data.hub.name
          this.$toast.success(`${data.data.message}`);
          this.unsavedChanges = false
        })
        .catch((error) => {
          this.spinners.btnSubmit = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
        .finally(() => {
          this.spinners.btnSubmit = false
        });
    },

    async store() {
      this.spinners.btnSubmit = true

      await this.$axios.post(this.$backend.COURIER.HUBS.STORE, this.transformedForm)
        .then(({ data }) => {
          this.itemName = data.data.hub.name
          this.$toast.success(`${data.data.message}`);
          this.unsavedChanges = false

          setTimeout(() => {
            this.$router.push({ name: 'Hubs' })
          }, 3000)
        })
        .catch((error) => {
          this.spinners.btnSubmit = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
        .finally(() => {
          this.spinners.btnSubmit = false
        });
    },

    validate() {
      this.$v.$touch()
    },

    async getForm (id = null) {
      var form = {
        name: '',
        address: '',
        city: '',
        zip: '',
        lng: null,
        lat: null,
        country_id: this.$store.state.countryId,
        couriers: [],
        scopes: [],
      };

      if(id) {
        this.isEdit = true
        this.spinners.loading = true

        await this.$axios.get(this.$backend.COURIER.HUBS.SHOW.replace("{id}", this.itemId))
          .then(({data}) => {
            this.itemName = data.name

            form.name = data.name;
            form.address = data.address;
            form.city = data.city;
            form.zip = data.zip;
            form.lng = data.lng;
            form.lat = data.lat;
            form.country_id = data.country_id || this.$store.state.countryId;
            form.couriers = data.couriers;
            form.scopes = data.scopes;

            this.spinners.loading = false;
          })
          .catch((error) => {
            this.$toast.error(`${error.response.data.message}`);
            setTimeout(() => { this.$router.push({ name: 'Hubs' }) }, 3000);
          })
          .finally(() => { this.unsavedChanges = false })
      }

      return form;
    },

    addCouriers() {
      this.form.couriers = this.form.couriers.concat(
        this.newCouriers.filter(newCourier => !this.form.couriers.some(c => c.id === newCourier.id)));
      this.newCouriers = [];
      this.addCouriersModalShow = false;
    },

    async getCoordinateFromAddress() {
      let geocoder = new google.maps.Geocoder();
      let address = `${this.form.address} ${this.form.zip} ${this.form.city} ${this.country?.iso2 ?? 'BE'}`;
      var vm = this;

      return new Promise(function (resolve, reject) {
        geocoder.geocode({ address: address },
          function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              vm.verified_address = results[0].formatted_address;
              vm.form.lat = results[0].geometry.location.lat();
              vm.form.lng = results[0].geometry.location.lng();
              vm.isCoordinateUptodate = true;
              resolve('Coordinate received');
            } else {
              reject(status);
            }
          });
      });
    },

    async getCoordinateFromPostalCode() {
      let geocoder = new google.maps.Geocoder();
      let country = this.country.iso2;
      let postalCode = this.form.zip;

      return new Promise(function (resolve, reject) {
        geocoder.geocode({ componentRestrictions: { country: country, postalCode: postalCode } },
          function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              resolve({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });
            } else {
              reject(status);
            }
          });
      });
    },

    setPlace() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete_address.getPlace();

      let street_number = "";
      let route = "";
      let postcode = "";
      let locality = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street_number = component.long_name;
            break;
          }

          case "route": {
            route = component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case "locality": {
            locality = component.long_name;
            break;
          }

          // case "administrative_area_level_1": {
          //   console.log("state: ", component.short_name);
          //   break;
          // }

          // case "country": {
          //   console.log("country: ", component.long_name);
          //   console.log("country: ", component.short_name);
          //   break;
          // }
        }
      }

      // street_number:  25
      // route:  Sint-Willibrordusplein
      // locality:  Heusden-Zolder
      // state:  Vlaams Gewest
      // country:  Belçika
      // country:  BE
      // address1:  Sint-Willibrordusplein 25
      // postcode:  3550
      // latitude:  51.0331523
      // longitude:  5.2814117

      this.verified_address = place.formatted_address;
      this.form.lat = place.geometry.location.lat();
      this.form.lng = place.geometry.location.lng();

      this.form.address = `${route} ${street_number}`;
      this.form.zip = postcode.replace(/\D/g, "");
      this.form.city = locality;

      setTimeout(() => {
        this.isCoordinateUptodate = true;
      }, 100);
    },
  }
}
</script>

<style>
.tab-menu > div:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  /* border-bottom: 1px solid #d8dbe0; */
}

form.hub-form .form-control,
form.hub-form option,
form.hub-form .vs__search,
form.hub-form .vs__dropdown-menu,
form.hub-form .vs__selected {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}
form.hub-form .vs__dropdown-toggle {
  padding: 2px 0 6px;
}
form.hub-form label.c-switch {
  margin-top: 0.3rem;
}
</style>

<style scoped>
section.address {
  background: #F8F0DF;
  padding: 1rem;
  border-radius: 0.5rem;
}
</style>
